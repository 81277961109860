.top-card-container {
  position: relative;
}

.top-card-container > .card {
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 400px;
  background: #1573ff;
  margin: auto;
  border-radius: 10px;
  padding: 8px;
  position: relative;
  aspect-ratio: 16/9;
}

.card > .circle {
  position: absolute;
  width: 400px;
  height: 400px;
  top: -130px;
  left: -140px;
  border-radius: 829px;
  background: #1e1671;
}

.card > .number {
  color: white;
  position: absolute;
  bottom: 15px;
  left: 15px;
  font-size: 22px;
  font-weight: bold;
}

.card > .amount {
  color: white;
  position: absolute;
  top: 20px;
  left: 15px;
  font-size: 35px;
  font-weight: bold;
}

.card-action-container {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.card-new-sale-container {
  position: absolute;
  bottom: 5px;
  right: 10px;
}

.btn-lock {
  position: relative;
  cursor: pointer;
  padding: 8px;
  text-align: center;
  line-height: 20px !important;
  background: white !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25) !important;
  border-radius: 6px !important;
  border: 0;
  max-width: 500px;
  display: block;
  font-size: 25px;
  font-weight: bold;
  margin: 0 0 10px 0;
  width: 40px;
  height: 40px;
  color: #374957;
}


.btn-new-sale {
  position: relative;
  cursor: pointer;
  padding: 8px;
  text-align: center;
  line-height: 20px !important;
  background: white !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25) !important;
  border-radius: 6px !important;
  border: 0;
  font-size: 25px;
  font-weight: bold;
  margin: 0 0 10px 0;
  color: #374957;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-new-sale > span {
  font-size: 15px;
  font-weight: 400;
}

.red {
  color: red;
}

.sales-history-title {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 23px;
  text-align: center;
}

.wallets-list-container {
  overflow: auto;
  white-space: nowrap;
  margin-top: 15px;
}

.wallet-element {
  display: inline-block;
  text-align: center;
  padding: 14px;
  margin-right: 20px;
  border: 2px solid #e2e2e2;
  border-radius: 6px;
}

.wallet-element.selected {
  border-color: #ff00a0;
}

.label {
  color: #999;
  font-size: 15px;
  padding: 10px 0;
}

.fab-new-sale {
  margin-bottom: 10px;
  margin-right: 10px;
}

.uid-card {
  font-size: 15px;
  font-weight: 500;
}