.unsetVolunteer {
  border: 1px solid #3880ff;
  border-radius: 50%;
  color: #3880ff;
}

.unsetVolunteer.active {
  border: 1px solid #3880ff;
  border-radius: 50%;
  background-color: #3880ff;
  color: white;
}

.shift-detail.loading {
  opacity: 0.5;
}

div.select-icon {
  display: none !important;
}

ion-select::part(icon) {
  display: none;
}

.research-card-container {
  height: 100%;
}

.support-choice-title {
  text-align: center;
  margin-bottom: 25px;
  margin-top: 5px;
}

.bracelet-container {
  background: white;
  border: 2px solid #e2e2e2;
  border-radius: 6px;
  padding: 10px;
  font-size: 20px;
  margin-bottom: 20px;
}

.bracelet-container.selected {
  border-color: #ff00a0;
}

.bracelet-block {
  position: relative;
  height: 80px;
  margin-bottom: 20px;
}

.bracelet-title {
  padding-bottom: 20px;
  padding-top: 12px;
  text-align: center;
}

.bracelet-ribbon {
  position: absolute;
  top: 6px;
  left: 0;
  width: 100%;
  height: 64px;
  background: #374957;
}

.bracelet-card {
  position: absolute;
  height: 76px;
  width: 115px;
  left: 50%;
  top: 0;
  background: white;
  transform: translateX(-50%);
  border: 1px solid #bfbfbf;
  border-radius: 3px;
}

.bracelet-label {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
}

.card-container {
  background: white;
  border: 2px solid #e2e2e2;
  border-radius: 6px;
  padding: 10px;
  font-size: 20px;
}

.card-container.selected {
  border-color: #ff00a0;
}

.card-block {
  position: relative;
  height: 80px;
  margin-bottom: 20px;
}

.card-title {
  padding-bottom: 20px;
  padding-top: 12px;
  text-align: center;
}

.card-block {
  width: 174px;
  height: 103px;
  margin: auto;
  display: block;
  box-shadow: 0px 1.07908654px 2.15817308px 1.07908654px rgba(0, 0, 0, 0.35);
  border-radius: 3px;
  margin-bottom: 20px;
  position: relative;
}

.card {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.card-label {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
}

.enter-support-label-container {
  display: block;
}

.support-label-title {
  font-size: 18px;
  padding-bottom: 6px;
}

.support-label-error {
  font-size: 15px;
  padding-top: 6px;
  color: red;
}

.input-label-container {
  position: relative;
}

.support-label {
  padding: 10px;
  width: 100%;
  display: block;
  border: 0;
  color: #374957;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 3px;
  font-size: 16px;
  height: 38px;
  -webkit-appearance: none;
  margin-top: 5px;
}

.btn {
  position: relative;
  cursor: pointer;
  width: 100%;
  padding: 11px;
  text-align: center;
  font-size: 16px;
  line-height: 20px !important;
  background: #ff00a0 !important;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25) !important;
  border-radius: 6px !important;
  border: 0;
  color: white;
  max-width: 500px;
  margin: 12px auto;
  display: block;
}

.btn.disabled {
  background: #fafafa !important;
  color: #bbbbbb !important;
  cursor: not-allowed;
  pointer-events: none;
}

.scan_camera {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  color: #ff00a0;
}

.locked {
  opacity: 0.5;
}

.card-locked {
  position: absolute;
  z-index: 9;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255,255,255,0.7);
  font-size: 27px;
}
