.transactions-list-container .transaction {
  margin-bottom: 10px;
  overflow: hidden;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 3px;
  padding: 12px;
  cursor: pointer;
}

.transactions-list-container .symbol {
  width: 15%;
  font-size: 32px;
  line-height: 32px;
  float: left;
  padding: 0px;
  display: flex;
  align-items: center;
}

.transactions-list-container .center {
  width: 60%;
  float: left;
  padding-top: 0px;
  height: 40px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.transactions-list-container .amount {
  width: 22%;
  float: left;
  height: 40px;
  text-align: right;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.transactions-list-container .chevron {
  width: 8%;
  float: left;
  height: 40px;
  padding-top: 12px;
  padding-left: 10px;
}

.transactions-list-container .amount.credit {
  color: #00b649;
}

.transactions-list-container .amount.debit {
  color: black;
}

.transactions-list-container .pos_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.transactions-list-container .hour {
  color: #aaa;
  font-size: 13px;
}

.details {
  border-top: 1px solid black;
  padding-top: 10px;
  margin-top: 10px;
}

.item-element {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
}

.item-element > div:first-child {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 10px;
}